
import { mapGetters } from 'vuex';
import { formatPrice } from '@/utilities';

export default {
  name: 'AppCartItems',
  props: {
    alternateEmptyText: {
      default: undefined,
      type: String,
    },
    hideButton: {
      default: false,
      type: Boolean,
    },
    hideItemAction: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('cart', [
      'cartItems',
      'cartBasePrice',
      'cartFinalPrice',
      'cartItemFinalPrice',
    ]),
    ...mapGetters('directory-person', ['userIsAustralian']),
    basePriceIsVisible() {
      return this.basePrice !== this.finalPrice;
    },
    basePrice() {
      return formatPrice(this.cartBasePrice, false);
    },
    finalPrice() {
      return formatPrice(this.cartFinalPrice, false);
    },
  },
  methods: {
    async toCheckout() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'cart_checkout',
      });

      const items = this.cartItems.map((item) => {
        return {
          item_id: item.product.code,
          price: this.cartItemFinalPrice(item.product.code) / 100,
          quantity: 1,
        };
      });
      const flatItems = items.reduce((acc, item) => {
        acc[`${item.item_id.toLowerCase()}_price`] = item.price;
        return acc;
      }, {});

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'begin_checkout',
        props: {
          checkout_trigger: 'Cart',
          currency: this.$t('texts.words.currency'),
          value: this.cartFinalPrice / 100,
          items,
          ...flatItems,
        },
      });
      await this.$router.push({
        path: this.localePath(`/checkout/cart`),
      });
      this.$emit('closeCartPanel');
    },
  },
};
