
import { mapActions, mapGetters } from 'vuex';
import { formatPrice } from '@/utilities';
export default {
  name: 'AppWillLegalAdviceAddOnModal',
  props: {
    isRecommendationPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('cart', ['isInCart']),
    ...mapGetters('product-prices', ['legalAdvicePrice']),
    price() {
      return this.formatPrice(this.legalAdvicePrice);
    },
    faqs() {
      return this.$t('components.legalAdviceAddOn.modal.faqs');
    },
  },
  methods: {
    formatPrice,
    ...mapActions('cart', ['addToCart']),
    addItemToCart() {
      this.$emit('addItemToCart');
      this.$emit('close');
    },
  },
};
