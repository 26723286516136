
import { mapActions, mapGetters } from 'vuex';
import GET_PRICES_QUERY from '@/graphql/queries/getPrices';
import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';
import CURRENT_USER_QUERY from '@/graphql/queries/CurrentUser';
import GET_ORDERS from '@/graphql/queries/GetOrders';
import GET_ACTIVE_INVITES_FOR_RECIPIENT from '@/graphql/queries/GetActiveInvitesForRecipient';
import { user, will, subscription } from '@/mixins/apollo';
import { pollUntilTruthy, formatError } from '@/utilities';

export default {
  name: 'AppCheckout',
  mixins: [user, will, subscription],
  props: {
    checkoutItems: {
      type: Array,
      default: () => [],
    },
    isGeneric: {
      type: Boolean,
      default: false,
    },
    productsWithCustomPricesToken: {
      type: String,
      default: null,
    },
    customProducts: {
      type: Array,
      default: () => [],
    },
    isUpdateCardDetails: {
      type: Boolean,
      default: false,
    },
    isUnlockAccount: {
      type: Boolean,
      default: false,
    },
    breadcrumbs: {
      type: Object,
      default: null,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    useCart: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openExitModal', 'orderCreated'],
  data() {
    return {
      acts: [
        'LOADING',
        'PRODUCT_SELECTION',
        'SUMMARY',
        'PAYMENT',
        'PROCESSING',
        'ERROR',
        'CHECKOUT',
      ],
      currentAct: null,
      currentScene: null,
      selectedItems: [],
      allItemsWithPrices: [],
      paymentProcessing: false,
      discountCode: null,
      discountId: null,
      discountData: null,
      paymentMethod: null,
      couponAllowed: false,
      stripeActionType: '',
      activePaidInvites: [],
      defaultItems: [],
      suggestedItems: [],
      partnerItems: [],
      giftItems: [],
      purchasedProducts: [],
      subscriptionOptOutModalIsVisible: false,
    };
  },
  apollo: {
    getPrices: {
      query: GET_PRICES_QUERY,
      variables() {
        return {
          products: Array.from(
            new Set([
              ...this.checkoutItems.map((product) => product.product),
              ...this.cartItemCodes,
            ])
          ),
          discountCode: this.discountCode,
        };
      },
      result({ data, error }) {
        if (error) {
          this.setCurrentAct('ERROR');
        } else {
          this.allItemsWithPrices = data.getPrices.pricedItems;
          this.discountData = data.getPrices.discount;
        }
      },
      skip() {
        return this.isGeneric || this.isUpdateCardDetails;
      },
    },
    purchasedProducts: {
      query: GET_PURCHASED_PRODUCTS_QUERY,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          userId: this.userId,
        };
      },
      skip() {
        return (
          !this.defaultItems.length ||
          Boolean(this.hasAfterpayPaymentIntentClientSecret)
        );
      },
      update(data) {
        const purchasedProducts = data?.getPurchasedProducts?.products || [];

        const hasAlreadyPurchasedAllDefaultItems = this.defaultItems.every(
          (defaultItem) => {
            return purchasedProducts.includes(defaultItem.product);
          }
        );
        if (
          !this.hasAfterpayPaymentIntentClientSecret &&
          !this.isUnlockAccount &&
          hasAlreadyPurchasedAllDefaultItems
        ) {
          console.error('User has already purchased all default items.');
          this.setCurrentAct('ERROR');
        }

        return purchasedProducts;
      },
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
      result({ data }) {
        if (!this.activePaidInvites.length) {
          this.discountCode = data.currentUser.user.latest_referral_coupon;
        }
      },
    },
    activePaidInvites: {
      query: GET_ACTIVE_INVITES_FOR_RECIPIENT,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          userId: this.userId,
        };
      },
      update({ getActiveInvitesForRecipient }) {
        const invites = getActiveInvitesForRecipient || [];
        const activePaidInvites = invites.filter(
          (invite) => invite.type === 'PAID'
        );

        this.updateSuggestedItems(activePaidInvites);

        const applicableInvite = this.findApplicableInvite(activePaidInvites);
        if (applicableInvite) {
          this.discountId = applicableInvite.id;
          this.discountCode = applicableInvite.code;
        }

        return activePaidInvites;
      },
      skip() {
        return !this.userId;
      },
    },
  },
  computed: {
    ...mapGetters('directory-person', ['userDetails', 'userIsAustralian']),
    ...mapGetters('coupon', ['couponLatestReferral']),
    ...mapGetters('orders', ['latestOrder']),
    ...mapGetters('cart', [
      'isCartEmpty',
      'cartItemPrices',
      'cartItemCodes',
      'canAddToCart',
      'isInCart',
      'isFreeCart',
      'subscriptionAutoRenewal',
    ]),
    ...mapGetters('product-prices', ['legalAdvicePrice']),
    selectedItemsAndCart() {
      return this.useCart ? this.cartItemPrices : [...this.selectedItems];
    },
    showProductSelection() {
      return (
        this.showProductSuggestions ||
        this.showPartnerSelection ||
        this.showGiftSelection
      );
    },
    showProductSuggestions() {
      return this.suggestedItems.length > 0;
    },
    showPartnerSelection() {
      return this.partnerItems.length > 0;
    },
    showGiftSelection() {
      return this.giftItems.length > 0;
    },
    selectedItemsWithPrices() {
      if (this.useCart) {
        return this.cartItemPrices;
      }

      return this.allItemsWithPrices
        ? this.selectedItemsAndCart.map(
            (selectedItem) =>
              this.allItemsWithPrices.find(
                (pricedItem) => pricedItem.product === selectedItem.product
              ) ?? selectedItem
          )
        : [];
    },
    defaultItemsWithPrices() {
      return this.allItemsWithPrices
        ? this.defaultItems.map((defaultItem) => ({
            ...this.allItemsWithPrices.find(
              (item) => item.product === defaultItem.product
            ),
            ...defaultItem,
          }))
        : [];
    },
    suggestedItemsWithPrices() {
      return this.allItemsWithPrices
        ? this.suggestedItems
            .filter(
              (suggestedItem) =>
                !this.purchasedProducts.includes(suggestedItem.product)
            )
            .map((suggestedItem) => {
              return {
                ...this.allItemsWithPrices.find((pricedItem) => {
                  return pricedItem.product === suggestedItem.product;
                }),
                ...suggestedItem,
              };
            })
            .map((suggestedItem) =>
              suggestedItem.product === 'LEGAL_ADVICE_BOOKING'
                ? {
                    ...suggestedItem,
                    payLaterPrice: this.legalAdvicePrice,
                  }
                : suggestedItem
            )
        : [];
    },
    partnerItemsWithPrices() {
      return this.allItemsWithPrices
        ? this.partnerItems
            .filter(
              (partnerItem) =>
                !this.purchasedProducts.includes(partnerItem.product)
            )
            .map((partnerItem) => {
              return {
                ...this.allItemsWithPrices.find((pricedItem) => {
                  return pricedItem.product === partnerItem.product;
                }),
                ...partnerItem,
              };
            })
        : [];
    },
    giftItemsWithPrices() {
      return this.allItemsWithPrices
        ? this.giftItems.map((giftItem) => {
            return {
              ...this.allItemsWithPrices.find((pricedItem) => {
                return pricedItem.product === giftItem.product;
              }),
              ...giftItem,
            };
          })
        : [];
    },
    checkoutItemsWithPrices() {
      return [...this.defaultItemsWithPrices, ...this.selectedItemsWithPrices];
    },
    expectedCostInCents() {
      return this.checkoutItemsWithPrices.reduce(
        (total, current) => total + current.finalPrice,
        0
      );
    },
    showCouponAlert() {
      return Boolean(
        this.discountCode && !this.$apollo.queries.getPrices.loading
      );
    },
    couponIsValid() {
      return !!this.discountData;
    },
    couponAlertMessage() {
      return this.couponIsValid
        ? this.$t('components.checkout.couponCodeApplied', {
            code: this.discountCode?.toUpperCase(),
          })
        : this.$t('components.checkout.invalidCouponCode', {
            code: this.discountCode?.toUpperCase(),
          });
    },
    billingAddress() {
      return this.userDetails?.residentialAddress
        ? {
            street: this.userDetails.residentialAddress?.streetAddress,
            suburb: this.userDetails.residentialAddress?.locality,
            state: this.userDetails.residentialAddress?.region,
            postcode: this.userDetails.residentialAddress?.postcode,
            country: this.userDetails.residentialAddress?.country,
          }
        : {};
    },
    afterpayPaymentIntent() {
      return this.$route.query.payment_intent;
    },
    isReturningFromAfterpay() {
      return !!this.afterpayPaymentIntent;
    },
    showAfterpay() {
      if (this.isReturningFromAfterpay) {
        return true;
      } else if (this.isUpdateCardDetails || !this.userIsAustralian) {
        return false;
      } else {
        return this.expectedCostInCents / 100 >= 40;
      }
    },
    submitDisabled() {
      return !this.paymentMethod || this.paymentProcessing;
    },
    isBackButtonVisible() {
      return (
        (this.isCurrentScene('PRODUCTS_PARTNER') &&
          this.showProductSuggestions) ||
        (this.isCurrentScene('PRODUCTS_GIFT') && this.showProductSuggestions) ||
        (this.isCurrentAct('SUMMARY') && this.showProductSelection) ||
        (this.isCurrentAct('PAYMENT') && this.showSummary) ||
        (this.isCurrentAct('PAYMENT') && this.showProductSelection)
      );
    },
    isZeroDollarCart() {
      return this.$ff.cardlessCheckout() && this.isFreeCart;
    },
    isCardlessCheckout() {
      const hasSubscription = this.isInCart('SUBSCRIPTION');

      return (
        this.isZeroDollarCart &&
        (!hasSubscription || (hasSubscription && !this.subscriptionAutoRenewal))
      );
    },
    crumbs() {
      const crumbStates = [];
      if (!this.showSummary) {
        crumbStates.push({
          text: this.breadcrumbs.start,
          state: 'complete',
        });
      }
      if (!this.isUnlockAccount) {
        crumbStates.push({
          text: this.$t('components.checkout.addOns'),
          state: this.breadcrumbState('PRODUCT_SELECTION'),
        });
      }
      if (this.showSummary) {
        crumbStates.push({
          text: this.$t('components.checkout.summary'),
          state: this.breadcrumbState('SUMMARY'),
        });
      }
      if (!this.isCardlessCheckout) {
        crumbStates.push({
          text: this.$t('components.checkout.payment'),
          state: this.breadcrumbState('PAYMENT'),
        });
      }
      crumbStates.push({
        text: this.breadcrumbs.finish,
        state: this.breadcrumbState('PROCESSING'),
      });
      return crumbStates;
    },
  },
  watch: {
    currentAct(nextAct) {
      if (nextAct === 'PAYMENT' && !this.showAfterpay) {
        this.paymentMethod = 'stripe';
      }
    },
    discountCode(newDiscountCode) {
      this.setDiscountCode(newDiscountCode);
    },
  },
  created() {
    this.discountCode = this.couponLatestReferral;

    this.currentAct = 'LOADING';

    const filteredCheckoutItems = this.useCart
      ? this.checkoutItems.filter(({ product }) => this.canAddToCart(product))
      : this.checkoutItems;

    this.defaultItems = filteredCheckoutItems.filter(
      ({ type }) => type === 'DEFAULT'
    );

    this.giftedOnlyItems = filteredCheckoutItems.filter(
      ({ type }) => type === 'GIFTED_ONLY'
    );

    this.suggestedItems = filteredCheckoutItems
      .filter(({ type }) => type === 'SUGGESTED')
      .map((item) => ({ ...item, isGifted: false }));

    if (!this.useCart) {
      this.selectedItems = this.checkoutItems
        .filter(({ type }) => type !== 'DEFAULT')
        .filter(({ product }) => this.isInCart(product));
    }

    this.partnerItems = filteredCheckoutItems.filter(
      ({ type }) => type === 'PARTNER'
    );

    this.giftItems = filteredCheckoutItems.filter(
      ({ type }) => type === 'GIFT'
    );

    this.couponAllowed =
      !this.isGeneric && !this.isUpdateCardDetails && !this.isUnlockAccount;

    this.stripeActionType = this.isUpdateCardDetails ? 'update' : 'checkout';

    this.hasAfterpayPaymentIntentClientSecret =
      !!this.$route.query.payment_intent_client_secret;

    if (this.hasAfterpayPaymentIntentClientSecret) {
      this.setCurrentAct('PAYMENT');
      this.paymentMethod = 'afterpay';
    } else if (this.showProductSelection) {
      this.setCurrentAct('PRODUCT_SELECTION');
      if (this.showProductSuggestions) {
        this.setCurrentScene('PRODUCTS_SUGGESTED');
      } else if (this.showPartnerSelection) {
        this.setCurrentScene('PRODUCTS_PARTNER');
      } else if (this.showGiftSelection) {
        this.setCurrentScene('PRODUCTS_GIFT');
      }
    } else {
      this.setCurrentAct(this.showSummary ? 'SUMMARY' : 'PAYMENT');
      if (this.isUpdateCardDetails || this.isUnlockAccount) {
        this.paymentMethod = 'stripe';
      }
    }
  },
  methods: {
    ...mapActions('cart', [
      'getCartItems',
      'setDiscountCode',
      'cardlessPayment',
    ]),
    ...mapActions('orders', ['getOrders']),
    isWillCheckout() {
      return this.isInCart('WILL');
    },
    isPoaCheckout() {
      return this.isInCart('POA');
    },
    userHasSeparatePoaAndWillInvites(activePaidInvites) {
      return (
        activePaidInvites.length >= 2 &&
        activePaidInvites.some((invite) => invite.products.includes('POA')) &&
        activePaidInvites.some((invite) => invite.products.includes('WILL'))
      );
    },
    userHasPoaInvite(activePaidInvites) {
      return activePaidInvites.some((invite) =>
        invite.products.includes('POA')
      );
    },
    userHasWillInvite(activePaidInvites) {
      return activePaidInvites.some((invite) =>
        invite.products.includes('WILL')
      );
    },
    userHasPurchasedWill() {
      return this.purchasedProducts.includes('WILL');
    },
    userHasPurchasedPoa() {
      return this.purchasedProducts.includes('POA');
    },
    updateSuggestedItems(activePaidInvites) {
      if (this.userHasSeparatePoaAndWillInvites(activePaidInvites)) {
        this.suggestedItems = this.suggestedItems.filter(
          (item) =>
            (this.isWillCheckout() && item.product !== 'POA') ||
            (this.isPoaCheckout() && item.product !== 'WILL')
        );
        this.giftedOnlyItems = this.giftedOnlyItems.filter(
          (item) =>
            (this.isWillCheckout() && item.product !== 'POA') ||
            (this.isPoaCheckout() && item.product !== 'WILL')
        );
      }

      const possibleGifts = [...this.suggestedItems, ...this.giftedOnlyItems];

      if (possibleGifts.length) {
        possibleGifts.forEach((item, index) => {
          if (
            (item.product === 'POA' &&
              this.userHasPoaInvite(activePaidInvites) &&
              !this.userHasPurchasedPoa()) ||
            (item.product === 'WILL' &&
              this.userHasWillInvite(activePaidInvites) &&
              !this.userHasPurchasedWill())
          ) {
            possibleGifts[index].isGifted = true;
            if (
              !this.selectedItems.some(
                (selectedItem) => selectedItem.product === item.product
              )
            ) {
              this.selectedItems.push(possibleGifts[index]);
            }
          }
        });
      } else if (this.currentScene === 'PRODUCTS_SUGGESTED') {
        this.setCurrentAct('PAYMENT');
      }
    },
    findApplicableInvite(activePaidInvites) {
      let applicableInvite = null;
      const willInvite = activePaidInvites.find(({ products }) =>
        products.includes('WILL')
      );
      const poaInvite = activePaidInvites.find(({ products }) =>
        products.includes('POA')
      );
      if (this.isWillCheckout()) {
        if (willInvite) {
          applicableInvite = willInvite;
        } else if (
          this.selectedItemsAndCart.find((item) => item.product === 'POA')
        ) {
          applicableInvite = poaInvite;
        }
      } else if (this.isPoaCheckout()) {
        applicableInvite = poaInvite;
        if (poaInvite) {
          applicableInvite = poaInvite;
        } else if (
          this.selectedItemsAndCart.find((item) => item.product === 'WILL')
        ) {
          applicableInvite = willInvite;
        }
      }
      return applicableInvite;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    setCurrentAct(act) {
      this.currentAct = act;
    },
    isCurrentAct(act) {
      return this.currentAct === act;
    },
    productSelectionNext(currentScene) {
      switch (currentScene) {
        case 'PRODUCTS_SUGGESTED': {
          if (this.showPartnerSelection) {
            this.setCurrentScene('PRODUCTS_PARTNER');
          } else if (this.showGiftSelection) {
            this.setCurrentScene('PRODUCTS_GIFT');
          } else if (this.showSummary) {
            this.currentAct = 'SUMMARY';
          } else {
            this.currentAct = 'PAYMENT';
          }
          break;
        }
        case 'PRODUCTS_PARTNER': {
          if (this.showGiftSelection) {
            this.setCurrentScene('PRODUCTS_GIFT');
          } else if (this.showSummary) {
            this.currentAct = 'SUMMARY';
          } else {
            this.currentAct = 'PAYMENT';
          }
          break;
        }
        case 'PRODUCTS_GIFT': {
          if (this.showSummary) {
            this.currentAct = 'SUMMARY';
          } else {
            this.currentAct = 'PAYMENT';
          }
          break;
        }
      }
    },
    setCurrentScene(scene) {
      this.currentScene = scene;
    },
    isCurrentScene(scene) {
      return this.currentScene === scene;
    },
    applyCoupon(couponCode) {
      this.discountCode = couponCode;
    },
    breadcrumbState(act) {
      const actIndex = this.acts.indexOf(act);
      const currentActIndex = this.acts.indexOf(this.currentAct);
      if (actIndex === currentActIndex) {
        return 'active';
      } else if (actIndex < currentActIndex) {
        return 'complete';
      }
      return null;
    },
    selectPaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },
    isSelectedTab(paymentMethod) {
      return this.paymentMethod === paymentMethod;
    },
    tabAttributes(paymentMethod) {
      return {
        level: this.isSelectedTab(paymentMethod) ? 'primary' : 'secondary',
      };
    },
    setPaymentProcessing(isProcessing) {
      this.paymentProcessing = isProcessing;
    },
    submitPayment() {
      switch (this.paymentMethod) {
        case 'stripe': {
          this.$refs.stripeComponent.submitPayment();
          break;
        }
        case 'afterpay': {
          this.$refs.afterpayComponent.submitPayment();
          break;
        }
      }
    },
    pollForAfterpayOrder() {
      return pollUntilTruthy(async () => {
        await this.getOrders();
        return this.latestOrder.externalPaymentId === this.afterpayPaymentIntent
          ? this.latestOrder
          : false;
      });
    },
    async createOrder() {
      this.setCurrentAct('PROCESSING');
      if (!this.isUpdateCardDetails) {
        const isOrderCreationSuccessful = await (this.isReturningFromAfterpay
          ? this.pollForAfterpayOrder()
          : this.checkPurchasedItems());
        if (isOrderCreationSuccessful) {
          await Promise.all([
            this.sendPurchasedProductsTrackingEvents(),
            this.setDiscountCode(null),
          ]);
        }
      }
      this.$emit('orderCreated');
    },
    isSubscriptionProduct(code) {
      return ['SUBSCRIPTION', 'UNLOCK', 'RECENT_UNLOCK'].includes(code);
    },
    checkPurchasedItems() {
      const purchasedItems = this.checkoutItemsWithPrices.map(
        (item) => item.product
      );
      return pollUntilTruthy(async () => {
        await this.getOrders();
        if (this.latestOrder?.status !== 'SUCCESSFUL') {
          return;
        }
        const products = this.latestOrder?.orderItems.map(
          (item) => item.product
        );
        if (products.some(this.isSubscriptionProduct)) {
          await pollUntilTruthy(async () => {
            const { data } = await this.$apollo.queries.subscription.refetch();
            return data?.getSubscription?.expiresAt > Date.now();
          });
        }
        return purchasedItems.every((purchasedItem) =>
          products.includes(purchasedItem)
        );
      });
    },
    async getLatestOrder() {
      const userId = this.userId;

      const { data } = await this.$apollo.query({
        query: GET_ORDERS,
        fetchPolicy: 'no-cache',
        variables: {
          userId,
        },
        skip() {
          return !userId;
        },
      });

      return data.getOrders.sort(function (x, y) {
        return Number(y.createdAt) - Number(x.createdAt);
      })[0];
    },
    async sendPurchasedProductsTrackingEvents() {
      const latestOrder = await this.getLatestOrder();
      const items = [];
      const currency = latestOrder.currency.toUpperCase();
      const additionalProps = {};
      for (const orderItem of latestOrder.orderItems) {
        items.push({
          item_id: orderItem.product,
          price: orderItem.valueInCents / 100,
          quantity: 1,
        });
        additionalProps[`${orderItem.product.toLowerCase()}_purchased_for`] =
          orderItem.valueInCents / 100;
        if (this.activePaidInvites.length) {
          additionalProps.invite_applied = true;
          additionalProps.invite_id = this.discountId;
        }
      }
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'purchase',
        props: {
          order_id: latestOrder.id,
          currency,
          value: latestOrder.valueInCents / 100,
          items,
          ...additionalProps,
        },
      });
    },
    setPreviousScene() {
      if (this.isCurrentAct('SUMMARY') && this.showProductSelection) {
        this.setCurrentAct('PRODUCT_SELECTION');
      } else if (this.isCurrentAct('PAYMENT')) {
        if (this.showSummary) {
          this.setCurrentAct('SUMMARY');
        } else if (this.showProductSelection) {
          this.setCurrentAct('PRODUCT_SELECTION');
        }
      } else if (
        this.isCurrentScene('PRODUCTS_PARTNER') &&
        this.showProductSuggestions
      ) {
        this.setCurrentScene('PRODUCTS_SUGGESTED');
      } else if (
        this.isCurrentScene('PRODUCTS_GIFT') &&
        this.showProductSuggestions
      ) {
        this.setCurrentScene('PRODUCTS_SUGGESTED');
      }
    },
    confirmCheckout() {
      this.setCurrentAct('PAYMENT');
      this.emitSubscriptionAutoRenewalEvent();
    },
    async confirmCardlessCheckout() {
      if (this.isInCart('SUBSCRIPTION')) {
        this.subscriptionOptOutModalIsVisible = true;
        this.emitSubscriptionAutoRenewalEvent();
      } else {
        await this.submitCardlessPayment();
      }
    },
    emitSubscriptionAutoRenewalEvent() {
      if (this.isInCart('SUBSCRIPTION') && this.isZeroDollarCart) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'click',
          props: {
            element_id: 'checkbox_subscription-auto-renewal',
            page_path: this.$route.path,
            click_value: this.subscriptionAutoRenewal,
          },
        });
      }
    },
    async submitCardlessPayment() {
      try {
        this.setPaymentProcessing(true);
        await this.cardlessPayment({
          productsWithCustomPricesToken: this.productsWithCustomPricesToken,
        });
        await this.createOrder();
      } catch (error) {
        this.$nuxt.$emit('snackbar', {
          icon: 'error',
          type: 'error',
          placement: 'top-right',
          relative: 'cart',
          text: formatError(error.message),
        });
        await this.getCartItems();
      } finally {
        this.setPaymentProcessing(false);
      }
    },
  },
};
