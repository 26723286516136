
import RuLogo from 'ruach/atoms/Logo';
import { mapGetters } from 'vuex';

export default {
  name: 'AppComponentOrganismsPurchasedProducts',
  components: {
    RuLogo,
  },
  props: {
    isWillCompleted: {
      type: Boolean,
      default: false,
    },
    isPoaCompleted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('orders', ['isLatestProductPurchased']),
    isUnlockPurchased() {
      return (
        this.isLatestProductPurchased('UNLOCK') ||
        this.isLatestProductPurchased('RECENT_UNLOCK')
      );
    },
    isWillPurchased() {
      return this.isLatestProductPurchased('WILL');
    },
    isPoaPurchased() {
      return this.isLatestProductPurchased('POA');
    },
    isLegalAdvicePurchased() {
      return this.isLatestProductPurchased('LEGAL_ADVICE');
    },
    isLegalAdviceBookingPurchased() {
      return this.isLatestProductPurchased('LEGAL_ADVICE_BOOKING');
    },
    isGiftPurchased() {
      return ['WILL_GIFT', 'POA_GIFT'].some((product) =>
        this.isLatestProductPurchased(product)
      );
    },
    isPartnerWillPurchased() {
      return this.isLatestProductPurchased('PARTNER_WILL');
    },
    productState() {
      if (this.isLegalAdvicePurchased || this.isLegalAdviceBookingPurchased) {
        return null;
      }
      if (this.isUnlockPurchased) {
        return 'UNLOCKED_WILL';
      }
      if (this.isWillPurchased && this.isWillCompleted) {
        return 'FINISHED_WILL';
      }
      if (this.isWillPurchased) {
        return 'UNFINISHED_WILL';
      }
      if (this.isPoaPurchased && this.isPoaCompleted) {
        return 'FINISHED_POA';
      }
      if (this.isPoaPurchased) {
        return 'UNFINISHED_POA';
      }
      return null;
    },
    finishedWillSteps() {
      return this.isFreeWillWeek
        ? this.$t('components.purchasedProductHighlight.finishedWill.fwwSteps')
        : this.$t('components.purchasedProductHighlight.finishedWill.steps');
    },
    unfinishedWillSteps() {
      return this.isFreeWillWeek
        ? this.$t(
            'components.purchasedProductHighlight.unfinishedWill.fwwSteps'
          )
        : this.$t('components.purchasedProductHighlight.unfinishedWill.steps');
    },
  },
};
