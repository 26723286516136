
export default {
  name: 'AppProductGateway',
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    primaryCtaList: {
      type: Array,
      default: () => [],
    },
    itemsCta: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: Number,
      default: 1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    progress: {
      type: Number,
      default: 0,
    },
    itemsDescription: {
      type: String,
      default: null,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    awaitingApproval() {
      return this.items.every((item) => item.status === 'awaiting-approval');
    },
    flaggedWill() {
      return this.items.some((item) => item.status === 'flagged');
    },
    tooltipContent() {
      return this.tooltip || '';
    },
  },
  created() {
    this.itemStatusIcons = {
      'in-progress': 'incomplete-pie',
      completed: 'check',
      'awaiting-approval': 'check',
      flagged: 'flag',
      approved: 'check',
    };
    this.itemActionIcons = {
      'not-started': 'arrow-right',
      'in-progress': 'pencil',
      completed: 'pencil',
      'awaiting-approval': null,
      approved: null,
      flagged: 'pencil',
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    openPage(path) {
      this.$router.push(path);
    },
  },
};
