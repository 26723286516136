
import { mapGetters, mapActions } from 'vuex';
import { throttle } from 'throttle-debounce';
import { Portal, PortalTarget } from 'portal-vue';

import tracking from '@/mixins/tracking';

export default {
  name: 'RuAch',
  components: {
    Portal,
    PortalTarget,
  },
  mixins: [tracking],
  middleware: [
    'redirect-if-not-logged-in.middleware',
    'userOnboardingPageRequired',
  ],
  data() {
    return {
      windowScrolled: false,
      portalObserver: null,
    };
  },
  computed: {
    ...mapGetters('ruach', ['shouldShowCharityConsentModal']),
    ...mapGetters('will', { willData: 'data' }),
    ...mapGetters('progress', ['willIsComplete']),
    ...mapGetters('banners', ['showOver60Banner']),
    styles() {
      return {
        '--has-header-banner': +this.showOver60Banner,
      };
    },
  },
  watch: {
    $route: {
      async handler($route) {
        this.setWhereabouts($route);
        if (window.scrollY > 0) {
          await this.$nextTick();
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }, 250);
        }
      },
      immediate: true,
    },
    willIsComplete: {
      handler(willIsComplete) {
        if (willIsComplete === true && this.willData.status === 'IN_PROGRESS') {
          this.$nuxt.$emit('sendTrackingEvent', {
            event: 'complete_all_modules',
            props: {
              will_id: this.willData.id,
            },
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.$updateShouldShowCharityConsent();
    this.observePortal();
  },
  beforeDestroy() {
    if (this.portalObserver) {
      this.portalObserver.disconnect();
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    ...mapActions('ruach', ['setWhereabouts']),
    onScroll: throttle(250, function () {
      const newWindowHasScrolled = window.scrollY > 0;
      if (newWindowHasScrolled !== this.windowScrolled) {
        this.windowScrolled = newWindowHasScrolled;
      }
    }),
    observePortal() {
      const callback = () => {
        this.$nextTick(() => {
          const screenOverlays = this.$refs.stack.$el.querySelectorAll(
            '.-\\-screen-overlay'
          );
          const hasOverlay = screenOverlays.length;
          if (hasOverlay) {
            Array.from(screenOverlays)
              .filter((screenOverlay) =>
                screenOverlay.classList.contains('app:modal')
              )
              .reverse()
              .forEach((modal, index) => (modal.dataset.index = index));
          }
          document.documentElement.classList.toggle(
            '--stack-has-overlay',
            hasOverlay
          );
          this.$refs.stack.$el.classList.toggle('--has-overlay', hasOverlay);
        });
      };
      const portalObserver = new MutationObserver(callback);
      portalObserver.observe(this.$refs.stack.$el, { childList: true });
      this.portalObserver = portalObserver;
    },
  },
};
