
import RuLogo from 'ruach/atoms/Logo';
import { mapActions, mapGetters } from 'vuex';
import { formatPrice } from '@/utilities';

export default {
  name: 'AppWillLegalAdviceAddOn',
  components: {
    RuLogo,
  },
  props: {
    pageRequirementsMet: {
      type: Boolean,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      legalAdviceInformationModalIsVisible: false,
      productHasBeenAddedToCart: false,
    };
  },
  computed: {
    ...mapGetters('cart', ['cartItemCodes', 'isInCart', 'canAddToCart']),
    ...mapGetters('directory-person', ['userIsAustralian']),
    ...mapGetters('affiliate', ['features']),
    ...mapGetters('product-prices', ['legalAdvicePrice']),
    price() {
      return this.formatPrice(this.legalAdvicePrice);
    },
    productIsInCart() {
      return this.isInCart('LEGAL_ADVICE_BOOKING');
    },
    addonIsVisible() {
      return (
        this.userIsAustralian &&
        this.features.checkoutLegalAdviceEnabled &&
        (this.canAddToCart('LEGAL_ADVICE_BOOKING') || this.productIsInCart) &&
        (this.pageRequirementsMet || this.productIsInCart)
      );
    },
  },
  methods: {
    formatPrice,
    ...mapActions('cart', ['addToCart']),
    async addLegalAdviceToCart() {
      await this.addToCart({ codes: ['WILL'], showSnackbar: false });
      await this.addToCart({
        codes: ['LEGAL_ADVICE_BOOKING'],
        showSnackbar: false,
      });
      this.legalAdviceInformationModalIsVisible = false;
      this.productHasBeenAddedToCart = true;
    },
    showLegalAdviceModal() {
      this.legalAdviceInformationModalIsVisible = true;
    },
  },
};
