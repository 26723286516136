
import { mapGetters } from 'vuex';
import { will, purchasedProducts } from '@/mixins/apollo';

export default {
  name: 'AppDashboardWillGateway',
  mixins: [will, purchasedProducts],
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['willStatus', 'isFreeWillWeek']),
    ...mapGetters('modules', ['modules']),
    ...mapGetters('directory-person', ['userDetails']),
    ...mapGetters('progress', { willProgress: 'will' }),
    ...mapGetters('flag', ['hasActivePlanFlags', 'isPlanModuleFlagged']),
    ...mapGetters('orders', ['isProductPurchased']),
    legalAdviceRequired() {
      return (
        this.willStatus === 'AWAITING_APPROVAL' &&
        this.isProductPurchased('LEGAL_ADVICE_BOOKING') &&
        !this.isProductPurchased('LEGAL_ADVICE')
      );
    },
    items() {
      return this.modules.map(
        ({ id, key, flagKey, displayName, path: cta }) => ({
          title: this.$t(displayName),
          status: this.getModuleStatus(key, flagKey),
          cta: this.willStatus === 'IN_PROGRESS' ? cta : '/will',
          id,
        })
      );
    },
    willStatusTooltipText() {
      switch (this.willStatus) {
        case 'AWAITING_APPROVAL':
          return this.$t('components.statusText.awaitingApproval');
        case 'FLAGGED':
          return this.$t('components.statusText.flagged');
        case 'APPROVED':
          return this.$t('components.statusText.approved');
        default:
          return null;
      }
    },
    ctaText() {
      if (this.isWillPurchased && this.willStatus === 'IN_PROGRESS') {
        return this.$t('components.willGateway.inProgress.cta');
      }
      if (this.willStatus === 'APPROVED') {
        return this.$t('components.willGateway.approved.cta');
      }
      if (this.willStatus === 'AWAITING_APPROVAL') {
        return this.$t('components.willGateway.awaitingApproval.cta');
      }
      if (this.userDetails.lastName) {
        return 'Complete my Will';
      }
      return this.$t('components.willGateway.notStarted.cta');
    },
    primaryCtaList() {
      if (this.willStatus === 'IN_PROGRESS' && this.willProgress.complete) {
        return [
          {
            text: this.$t('components.willGateway.submitYourWill'),
            link: '/will',
            icon: 'pi-arrow-right',
          },
        ];
      } else if (this.willStatus === 'NOT_STARTED') {
        return [
          {
            text: this.$t('components.willGateway.startMyWill'),
            link: '/will',
            icon: 'pi-arrow-right',
          },
        ];
      } else if (this.willStatus === 'IN_PROGRESS') {
        return [
          {
            text: this.$t('components.willGateway.completeMyWill'),
            link: '/will',
            icon: 'pi-arrow-right',
          },
        ];
      } else if (this.willStatus === 'AWAITING_APPROVAL') {
        return [
          {
            text: this.$t('components.willGateway.downloadMyWill'),
            link: '/will',
            icon: 'pi-download',
            disabled: true,
          },
        ];
      } else if (this.willStatus === 'FLAGGED') {
        return [
          {
            text: this.$t('components.willGateway.editMyWill'),
            link: '/will',
            icon: 'pi-arrow-right',
          },
        ];
      } else if (this.willStatus === 'APPROVED') {
        return [
          {
            text: this.$t('components.willGateway.downloadMyWill'),
            link: '/will',
            icon: 'pi-download',
          },
          {
            text: this.$t('components.willGateway.updateMyWill'),
            link: '/will',
            icon: 'pi-pencil',
            class: 'p-button-outlined',
          },
        ];
      }
      return [
        {
          link: '/will',
          text: this.$t('components.willGateway.editMyWill'),
        },
      ];
    },
    itemsDescription() {
      const totalModules = Object.keys(this.willProgress.modules).length;
      const completedModules = Object.keys(this.willProgress.modules).filter(
        (module) => {
          return this.willProgress.modules[module].decimal === 1;
        }
      ).length;
      return `${completedModules} ${this.$t(
        'components.willGateway.outOf'
      )} ${totalModules} ${this.$t('components.willGateway.areComplete')}`;
    },
    showFlaggedAlert() {
      return (
        this.hasActivePlanFlags &&
        ['FLAGGED', 'IN_PROGRESS'].includes(this.willStatus)
      );
    },
  },
  methods: {
    getModuleStatus(moduleKey, flagKey) {
      if (
        this.isPlanModuleFlagged(flagKey) &&
        ['FLAGGED', 'IN_PROGRESS'].includes(this.willStatus)
      ) {
        return 'flagged';
      }
      if (this.willStatus === 'APPROVED') {
        return 'approved';
      }
      if (this.willStatus === 'AWAITING_APPROVAL') {
        return 'awaiting-approval';
      }
      if (this.willStatus === 'NOT_STARTED') {
        return 'not-started';
      }
      if (this.willProgress.modules[moduleKey].decimal === 1) {
        return 'completed';
      } else if (this.willProgress.modules[moduleKey].decimal > 0) {
        return 'in-progress';
      }
      return 'not-started';
    },
  },
};
