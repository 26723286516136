
import { mapGetters } from 'vuex';
import { will, beneficiaries, user } from '@/mixins/apollo';
import { dobIsUnderAge } from '@/utilities';

export default {
  name: 'PagesAffiliateWillEstateDistribution',
  mixins: [will, beneficiaries, user],
  data() {
    return {
      distributions: [],
      valid: false,
      isPrimaryEstateSplitEvenly: false,
    };
  },
  computed: {
    ...mapGetters('directory-person', ['isUserChild']),
    ...mapGetters('ruach', ['shouldShowCharityConsentPage']),
    isComplete() {
      return !!this.valid;
    },
    legalAdviceAddOnIsVisible() {
      return this.beneficiaries.some(
        ({ directoryPerson }) =>
          !!directoryPerson &&
          (this.isUserChild(directoryPerson) ||
            !directoryPerson.ageEighteenOrAbove ||
            dobIsUnderAge(directoryPerson.dateOfBirth, 22))
      );
    },
    submitLabel() {
      return this.$t('forms.labels.continue');
    },
  },
  watch: {
    will(newWillValue) {
      this.isPrimaryEstateSplitEvenly = newWillValue.isPrimaryEstateSplitEvenly;
    },
  },
  methods: {
    checkValid(valid) {
      this.valid = valid;
    },
    async submit() {
      await this.updateWillIsPrimaryEstateSplitEvenly(
        this.isPrimaryEstateSplitEvenly
      );
      await Promise.all(
        this.beneficiaries.map((beneficiary, i) => {
          return this.updateBeneficiaryCore(
            beneficiary,
            this.isPrimaryEstateSplitEvenly ? null : this.distributions[i],
            beneficiary.isBackupEstateSplitEvenly
          );
        })
      );
      await this.refetchBeneficiaries();

      const hasPeople = this.beneficiaries.some(
        (beneficiary) => !!beneficiary.directoryPerson
      );

      if (hasPeople) {
        this.$router.push({
          path: this.localePath(
            `/will/estate/backup-beneficiaries/${this.backupQueue[0].id}`
          ),
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'complete_module',
          props: {
            will_id: this.willId,
            module_name: 'Estate',
          },
        });

        await this.refetchWill();
        await this.$updateShouldShowCharityConsent();
        const path = this.localePath(
          this.shouldShowCharityConsentPage.includes('estate')
            ? '/will/charity-consent?next-module=gifts'
            : '/will/gifts'
        );
        this.$router.push({
          path,
        });
      }
    },
  },
};
