
import { mapGetters, mapActions } from 'vuex';
import {
  VAULT_ITEM_KEY,
  VAULT_ITEM_TYPE,
  TOOL_TYPE,
  TOOL_STATUS,
  WILL_STATUS,
} from '@/utilities/constants';

const {
  SITUATION_FAMILY_CHILD,
  SITUATION_FAMILY_PARTNER,
  SITUATION_FAMILY_OTHER,
  DECISION_EXCLUSION_DEATH,
  SITUATION_FINANCIAL_ASSETS_SIZE,
  SITUATION_FINANCIAL_ASSETS_LOCATION,
  SITUATION_FINANCIAL_ASSET_TYPE,
} = VAULT_ITEM_KEY;
const {
  HAS_CHILD_WITH_PREVIOUS_PARTNER,
  HAS_UPCOMING_CHANGE_OF_MARITAL_STATUS,
  ESTATE_BENEFICIARY,
  HAS_FAMILY_MEMBER_WITH_DISABILITY,
  HAS_SIGNIFICANT_EXCLUDING_PRIMARY_RESIDENCE,
  HAS_INTERNATIONAL,
  IS_BUSINESS_OWNER,
  IS_SELF_MANAGED_PENSION_FUND_OWNER,
  HAS_FAMILY_TRUST_INTEREST,
} = VAULT_ITEM_TYPE;

export default {
  name: 'PagesAffiliateOnboardingRecommendation',
  layout: 'ruach',
  data() {
    return {
      isLegalAdviceUpsellModalVisible: false,
      isReasonsVisible: false,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('tool', ['getToolByType']),
    ...mapGetters('vault-item', ['vaultItems']),
    ...mapGetters('affiliate', ['slug']),
    ...mapGetters('product-prices', ['willPrice', 'legalAdvicePrice']),
    ...mapGetters('directory-person', ['userDetails']),
    isOnComplexPage() {
      return this.$router.currentRoute.path.endsWith('/will-with-legal-advice');
    },
    isOnSimplePage() {
      return this.$router.currentRoute.path.endsWith('/will');
    },
    i18nBasePath() {
      switch (true) {
        case this.isOnComplexPage:
          return 'pages.onboarding.recommendation.complex';
        case this.isOnSimplePage:
          return 'pages.onboarding.recommendation.simple';
        default:
          return 'pages.onboarding.recommendation.slightlyComplex';
      }
    },
    wilAndLegalAdvicePrice() {
      return this.willPrice + this.legalAdvicePrice;
    },
    recommendationReasons() {
      return this.vaultItems.reduce((reasons, { key, type, value }) => {
        if (this.isRecommendationReason(key, type, value)) {
          reasons.push(this.getRecommendationReason(key, type));
        }
        return reasons;
      }, []);
    },
  },
  mounted() {
    this.getProductPrices();

    const tool = this.getToolByType(TOOL_TYPE.ONBOARDING_NEEDS_ASSESSMENT);
    if (!tool || tool.status !== TOOL_STATUS.COMPLETED) {
      this.$router.push({ path: this.localePath('/onboarding/user') });
    }
    this.getVaultItems({
      ids: tool.vaultItemIds,
    });
  },
  methods: {
    ...mapActions('cart', ['addToCart']),
    ...mapActions('will', ['updateWillStatus']),
    ...mapActions('vault-item', ['getVaultItems']),
    ...mapActions('product-prices', ['getProductPrices']),
    async addWillToCartAndStartWill(withLegalAdvice = false) {
      this.sendSignUpEvent();

      await this.addToCart({ codes: ['WILL'], showSnackbar: false });
      if (withLegalAdvice) {
        await this.addToCart({
          codes: ['LEGAL_ADVICE_BOOKING'],
          showSnackbar: false,
        });
      }
      await this.updateWillStatus(WILL_STATUS.IN_PROGRESS);
      this.$router.push({ path: this.localePath('/will/about-yourself/name') });
    },
    showLegalAdviceUpsellModal() {
      this.isLegalAdviceUpsellModalVisible = true;
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'click',
        props: {
          element_id: `recommendation-show_legal_advice_upsell_modal`,
          page_path: this.$route.path,
        },
      });
    },
    closeLegalAdviceUpsellModal() {
      this.isLegalAdviceUpsellModalVisible = false;
    },
    skipWill() {
      this.sendSignUpEvent();
      const slug = `/${this.slug}`;
      this.$router.push({ path: this.localePath(slug) });
    },
    sendSignUpEvent() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'sign_up',
        props: {
          userId: this.userId,
        },
      });
    },
    isRecommendationReason(key, type, value) {
      const conditions = {
        [SITUATION_FAMILY_CHILD]: HAS_CHILD_WITH_PREVIOUS_PARTNER,
        [SITUATION_FAMILY_PARTNER]: HAS_UPCOMING_CHANGE_OF_MARITAL_STATUS,
        [DECISION_EXCLUSION_DEATH]: ESTATE_BENEFICIARY,
        [SITUATION_FAMILY_OTHER]: HAS_FAMILY_MEMBER_WITH_DISABILITY,
        [SITUATION_FINANCIAL_ASSETS_SIZE]:
          HAS_SIGNIFICANT_EXCLUDING_PRIMARY_RESIDENCE,
        [SITUATION_FINANCIAL_ASSETS_LOCATION]: HAS_INTERNATIONAL,
        [SITUATION_FINANCIAL_ASSET_TYPE]: [
          IS_BUSINESS_OWNER,
          IS_SELF_MANAGED_PENSION_FUND_OWNER,
          HAS_FAMILY_TRUST_INTEREST,
        ],
      };

      return (
        conditions[key] &&
        (Array.isArray(conditions[key])
          ? conditions[key].includes(type)
          : conditions[key] === type) &&
        (value === null || value === true)
      );
    },
    getRecommendationReason(key, type) {
      const copyPrefix = `pages.onboarding.recommendation.complex.reason.${key}.${type}`;
      return {
        icon: require(
          `@/assets/img/vault-items/${this.$t(`${copyPrefix}.icon`)}`
        ),
        headline: this.$t(`${copyPrefix}.headline`),
        description: this.$t(`${copyPrefix}.description`),
      };
    },
    toggleReasonsVisibility() {
      this.isReasonsVisible = !this.isReasonsVisible;
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'click',
        props: {
          element_id: `recommendation-toggle_reasons--${this.isReasonsVisible}`,
          page_path: this.$route.path,
        },
      });
    },
  },
};
