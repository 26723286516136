
import { mapActions, mapGetters } from 'vuex';
import { will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillReview',
  mixins: [will],
  data: () => ({
    isSubmitting: false,
    reviewPromptIsVisible: false,
    alertFlagsVisible: false,
  }),
  computed: {
    ...mapGetters(['willStatus', 'isFreeWillWeek']),
    ...mapGetters('cart', [
      'isInCart',
      'cartItems',
      'cartFinalPrice',
      'cartItemFinalPrice',
    ]),
    ...mapGetters('orders', ['isProductPurchased']),
    ...mapGetters('will', { willData: 'data' }),
    ...mapGetters('progress', { willProgress: 'will' }),
    ...mapGetters('flag', [
      'planFlags',
      'hasActivePlanFlags',
      'activePlanFlagsCount',
    ]),
    title() {
      return this.willProgress.complete
        ? this.$t('pages.will.review.yourWillIsNearlyFinished')
        : this.$t('pages.will.review.completeWillBeforeReview');
    },
    subtitle() {
      return this.willProgress.complete
        ? this.$t('pages.will.review.whatHappensNext')
        : this.$t('pages.will.review.whatHappensWhenYourWillIsSubmitted');
    },
    hasPurchasedWill() {
      return (
        this.isProductPurchased('WILL') || this.isProductPurchased('WILL_X2')
      );
    },
    showWillPurchasedAlert() {
      return this.hasPurchasedWill && !this.hasLegalAdviceBookingInCart;
    },
    ctaId() {
      if (
        this.willProgress.complete &&
        this.hasPurchasedWill &&
        !this.hasLegalAdviceBookingInCart
      ) {
        return 'resubmit';
      } else if (this.willProgress.complete) {
        return 'purchase';
      }
      return 'continue';
    },
    ctaText() {
      if (
        this.willProgress.complete &&
        this.hasPurchasedWill &&
        !this.hasLegalAdviceBookingInCart
      ) {
        return this.$t('pages.will.review.submitNow');
      } else if (this.willProgress.complete) {
        return this.isFreeWillWeek
          ? this.$t('pages.will.review.submitNow')
          : this.$t('pages.will.review.submitAndPay');
      }
      return this.$t('pages.will.review.continue');
    },
    flagsTitle() {
      return this.hasActivePlanFlags
        ? this.$t('pages.will.review.flags.title.outstanding', {
            flagsCount: this.activePlanFlagsCount,
          })
        : this.$t('pages.will.review.flags.title.completed');
    },
    flagsSubtitle() {
      return this.hasActivePlanFlags
        ? this.$t('pages.will.review.flags.subtitle.outstanding')
        : this.$t('pages.will.review.flags.subtitle.completed');
    },
    isCtaDisabled() {
      return this.isSubmitting || this.hasActivePlanFlags;
    },
    hasLegalAdviceBookingInCart() {
      return this.isInCart('LEGAL_ADVICE_BOOKING');
    },
  },
  async mounted() {
    if (!this.hasPurchasedWill) {
      await this.addToCart({ codes: ['WILL'], showSnackbar: false });
    }
    if (this.activePlanFlagsCount > 0) {
      this.alertFlagsVisible = true;
    }
  },
  methods: {
    ...mapActions('will', {
      submitWillAction: 'submitWill',
    }),
    ...mapActions('cart', ['addToCart']),
    showReviewPrompt() {
      this.reviewPromptIsVisible = true;
    },
    hideReviewPrompt() {
      this.reviewPromptIsVisible = false;
    },
    async ctaAction() {
      if (this.willProgress.complete) {
        if (this.hasPurchasedWill) {
          if (this.hasLegalAdviceBookingInCart) {
            await this.submitWill(false);
            return await this.checkout();
          } else if (this.will.sourceWillId) {
            this.showReviewPrompt();
          } else {
            await this.submitWill();
          }
        } else {
          return await this.checkout();
        }
      } else {
        return this.openWillPage();
      }
    },
    async submitWill(redirectToHome = true) {
      this.isSubmitting = true;
      try {
        await this.submitWillAction();
        await this.updateWillMeta();
        await this.refetchWill();
      } catch (error) {
        console.error('Will submission failed.');
      } finally {
        this.isSubmitting = false;
        if (redirectToHome) {
          await this.$router.push({
            path: this.localePath('/will'),
          });
        }
      }
    },
    async checkout() {
      const items = this.cartItems.map((item) => {
        return {
          item_id: item.product.code,
          price: this.cartItemFinalPrice(item.product.code) / 100,
          quantity: 1,
        };
      });

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'begin_checkout',
        props: {
          checkout_trigger: 'Will complete',
          currency: this.$t('texts.words.currency'),
          value: this.cartFinalPrice / 100,
          items,
        },
      });
      await this.refetchWill();
      await this.$router.push({
        path: this.localePath('/checkout/cart'),
      });
    },
    async resubmitWill() {
      this.isSubmitting = true;

      if (this.willMeta.notify_charities === false) {
        this.willMeta.notify_charities = null;
        await this.updateWillMeta();
      }
      try {
        const willData = await this.submitWillAction();
        if (willData) {
          await this.$updateShouldShowCharityConsent();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    openWillPage() {
      this.$router.push({ path: this.localePath('/will') });
    },
  },
};
