
import { mapGetters } from 'vuex';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillFuneralOtherRequests',
  mixins: [user, will],
  computed: {
    ...mapGetters('progress', { willProgress: 'will' }),
    submitLabel() {
      return this.willMeta.funeral_note
        ? this.$t('forms.labels.continueToReview')
        : this.$t('forms.labels.skipToReview');
    },
    nextStep() {
      if (this.willProgress.complete) {
        return '/will/review';
      }
      return '/will';
    },
    prevStep() {
      return ['cremation', 'burial'].includes(this.willMeta.funeral_type)
        ? '/will/funeral/location'
        : '/will/funeral';
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'complete_module',
        props: {
          will_id: this.willId,
          module_name: 'Funeral',
        },
      });

      const path = this.localePath(this.nextStep);
      this.$router.push({
        path,
      });
    },
  },
};
