import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0f371cf4"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppWillFlagAlerts: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/FlagAlerts.vue').default,AppBigNumber: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/BigNumber.vue').default,WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppBox: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Box.vue').default,AppWillSkipApprovalModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/SkipApprovalModal.vue').default})
